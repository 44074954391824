/** @jsx jsx */
import { useCallback, useMemo } from "react"
import { jsx, Flex, Link, Image, Text } from "theme-ui"
import { useStaticQuery, graphql } from "gatsby"

import discordImg from "../../images/discord.svg"

const imgMap = {
  discord: discordImg,
}

function DiscordLink(props) {
  const {
    data: { links },
  } = useStaticQuery(query)

  const activeLinks = useMemo(() => links.filter(item => item.hide !== true), [
    links,
  ])

  const renderLinks = useCallback(
    () =>
      activeLinks.map((item, i) => (
        <Link
          key={item.id}
          href={item.uri}
          target="_blank"
          mr={`${i - links.length !== -1 ? 2 : 0}`}
          disabled={item.inActive}
          sx={{
            pointerEvents: () => (item.inActive ? "none" : "auto"),
            cursor: "pointer",
          }}
        >
          <Flex
            sx={{
              borderWidth: "1px",
              borderStyle: "solid",
              borderColor: "#000",
              borderRadius: "5px",
            }}
            px={3}
            py={2}
          >
            <Image
              sx={{
                opacity: () => (item.inActive ? 0.42 : 1),
                marginRight: 2,
              }}
              src={imgMap[item.id]}
              alt={item.alt}
            />
            <Text sx={{ fontSize: 3, fontWeight: "bold", color: "#000" }}>
              {item.label || "Discord"}
            </Text>
          </Flex>
        </Link>
      )),
    [activeLinks]
  )
  if (activeLinks.length < 1) return null
  return <Flex {...props}>{renderLinks()}</Flex>
}

export default DiscordLink

const query = graphql`
  query DiscordLinkQuery {
    data: settingsJson {
      links: discord {
        id
        alt
        uri
        hide
        inActive
        label
      }
    }
  }
`
